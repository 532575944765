import React from "react";
import styles from "./style.module.scss";

export default function Button(props) {
    return (
        <button
            className={"btn " + styles.button}
            onClick={props.onClick}
            disabled={props.disabled}
            href={props.href}
            type={
                props.type !== null && props.type !== "" ? props.type : "button"
            }
        >
            {props.text}
        </button>
    );
}
